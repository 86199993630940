export const Beatport = () => {
  return (
    <svg width={72} height={72} viewBox="0 0 72 72">
      <title>beatport</title>
      <g fill="currentColor">
        <path d="M54.788 37.487C54.788 26.742 46.308 18 35.885 18c-10.423 0-18.902 8.742-18.902 19.487 0 4.973 1.819 9.707 5.123 13.336l.001-.001a1.147 1.147 0 001.656.05l2.037-1.976a1.234 1.234 0 00.054-1.702l.002-.002c-2.404-2.64-3.729-6.088-3.729-9.705 0-7.82 6.172-14.183 13.758-14.183 7.586 0 13.758 6.363 13.758 14.183 0 3.617-1.323 7.065-3.729 9.705l.005.004a1.233 1.233 0 00.056 1.7l2.038 1.976c.47.458 1.212.433 1.655-.051l-.002-.001c3.303-3.628 5.122-8.362 5.122-13.333M62.043 36.92a26.74 26.74 0 00-1.149-7.786h.001c0-.002-.003-.004-.003-.007l-.002-.008c-.198-.626-.848-.976-1.461-.78l-2.672.856c-.617.197-.962.872-.772 1.509l-.002.002c.606 2.003.917 4.093.917 6.215 0 3.746-.984 7.42-2.845 10.626l.003.003a1.228 1.228 0 00.406 1.65l2.4 1.484a1.151 1.151 0 001.606-.414 26.619 26.619 0 003.573-13.35" />
        <path d="M17.99 47.55a21.187 21.187 0 01-2.845-10.627c0-2.122.31-4.211.917-6.214h.001v-.002c.19-.637-.155-1.312-.77-1.51l-2.674-.858c-.613-.196-1.264.154-1.46.784l-.005-.003A26.832 26.832 0 0010 36.923a26.64 26.64 0 003.574 13.349l.004-.002a1.15 1.15 0 001.602.408l2.4-1.484c.55-.34.731-1.073.408-1.644h.001z" />
      </g>
    </svg>
  )
}
