export const LogoFA = () => {
  return (
    <svg
      data-name="Calque 1"
      width="33.82mm"
      height="29.75mm"
      viewBox="0 0 95.85 84.32"
    >
      <title>Logo Fredelux Agency</title>
      <path d="M54.28 0H0v84.12h11.66V36.3h25.41c3.75 0 3.75 0 4.63-4.07H11.83V3.8h38.19c3.51.01 3.51.05 4.26-3.8zM40.05 56.29l-1.5 4h3.06c10.08 0 20.16.05 30.24 0 1.86 0 2.78.47 3.47 2.26 2.57 6.66 5.29 13.26 8 19.84.32.76 1.17 1.81 1.81 1.85 3.44.18 6.9.09 10.67.09L61.89.33c-1.8 4.58-3.27 8.53-4.93 12.41a6 6 0 00.07 5c4.89 11.9 9.7 23.83 14.54 35.75.35.87.65 1.76 1 2.85H40.05z" />
      <path
        d="M54.28 0c-.75 3.85-.75 3.81-4.26 3.81H11.83v28.43H41.7c-.88 4.07-.88 4.07-4.63 4.07H11.66v47.82H0V0h54.28zM40.05 56.29h32.61c-.39-1.08-.69-2-1-2.85-4.83-11.92-9.65-23.85-14.54-35.75a6 6 0 01-.07-5C58.7 8.82 60.17 4.86 61.98.28l33.91 84c-3.76 0-7.22.1-10.67-.09-.65 0-1.5-1.08-1.81-1.85-2.75-6.59-5.47-13.19-8-19.84-.69-1.79-1.62-2.28-3.47-2.26-10.08.1-20.16 0-30.24 0h-3.14z"
        fill="currentColor"
      />
    </svg>
  )
}
